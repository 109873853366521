import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CertificateService {
  constructor(private http: HttpClient) {}

  url = environment.serverUrl + '/userProgress';
  apiUrl = environment.serverUrl + '/certificate';

  getCertificateDetail2(courseId, userCourseId, userId, maxAttempt) {
    const data: any = Object.assign({}, null);
    data['userCourseId'] = userCourseId;
    data['courseId'] = courseId;
    data['userId'] = userId;
    data['maxAttempt'] = maxAttempt;
    // const obj = {
    //   'userCourseId': userCourseId,
    //   'courseId': courseId,
    //   'userId': userId,
    //   'maxAttempt':maxAttempt
    // }

    return this.http.get<any>(this.url + '/getCertificateDetail', { params: data }).pipe(
      map((data) => {
        // console.log(data)
        return {
          firstName: data.userData.firstName,
          lastName: data.userData.lastName,
          title: data.userData.title,
          certificateId: data.userData.certificateId,
          certificateDetails: data.data.map((result) => {
            return {
              completionDate: result.completionDate,
              courseName: result.Course.courseName,
              UserCourseModules: result.UserCourseModules.map((modules) => {
                return {
                  moduleName: modules.CourseModule.moduleName,
                };
              }),
              ExamResults: result.ExamResults.map((exam) => {
                return {
                  percentage: exam.percentage,
                  result: exam.result,
                  completionDate: exam.completionDate,
                };
              }),
            };
          }),
        };
      })
    );
  }

  getCertificateDetail(courseId, userCourseId, userId) {
    const obj = {
      userCourseId: userCourseId,
      courseId: courseId,
      userId: userId,
    };

    return this.http.get<any>(this.url + '/getCertificateDetail', { params: obj }).pipe(
      map((data) => {
        // console.log(data)
        return {
          firstName: data.userData.firstName,
          lastName: data.userData.lastName,
          title: data.userData.title,
          certificateId: data.userData.certificateId,
          certificateDetails: data.data.map((result) => {
            return {
              completionDate: result.completionDate,
              courseName: result.Course.courseName,
              UserCourseModules: result.UserCourseModules.map((modules) => {
                return {
                  moduleName: modules.CourseModule.moduleName,
                };
              }),
              ExamResults: result.ExamResults.map((exam) => {
                return {
                  percentage: exam.percentage,
                  result: exam.result,
                  completionDate: exam.completionDate,
                };
              }),
            };
          }),
        };
      })
    );
  }

  getVerifyCertificateDetail(courseId, userCourseId, userId) {
    const obj = {
      userCourseId: userCourseId,
      courseId: courseId,
      userId: userId,
    };

    return this.http.get<any>(this.apiUrl, { params: obj }).pipe(
      map((data) => {
        // console.log(data)
        return {
          firstName: data.userData.firstName,
          lastName: data.userData.lastName,
          title: data.userData.title,
          certificateId: data.userData.certificateId,
          certificateDetails: data.data.map((result) => {
            return {
              completionDate: result.completionDate,
              courseName: result.Course.courseName,
              UserCourseModules: result.UserCourseModules.map((modules) => {
                return {
                  moduleName: modules.CourseModule.moduleName,
                };
              }),
              ExamResults: result.ExamResults.map((exam) => {
                return {
                  percentage: exam.percentage,
                  result: exam.result,
                  completionDate: exam.completionDate,
                };
              }),
            };
          }),
        };
      })
    );
  }

  sendCertificate(pdfData: any, certificateId: any, userId: any) {
    return this.http.post<any>(this.url + '/sendCertificate', {
      pdf: pdfData,
      certificateId: certificateId,
      userId: userId,
    });
  }
}
